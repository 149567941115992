section {
    margin: 1rem;
}

body {
    background-image: url('../resources/pic/wallpaper.gif');
}

label {
    padding-left: 0;
    padding-right: 10px;
}

#logo {
    display: flex;
    justify-content: center;
}

#caution {
    background-color: rgba(255, 255, 255, 0.88);
    padding: 1rem;
    text-align: center;
}

.index_selection_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.box {
    background-color: rgba(255, 255, 255, 0.88);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    width: 160px;
}

.box > img,
.noimage {
    width: 160px;
    height: 160px;
}

.title {
    margin-top: 1rem;
}

.title > p {
    margin: 0;
}

#producer {
    background-color: rgba(255, 255, 255, 0.88);
    padding: 1rem;
}

#producer dt {
    margin: 1rem 0;
}

#producer dd {
    margin: 0.15rem 0;
}

#producer p {
    margin: 0;
}

#version {
    background-color: rgba(255, 255, 255, 0.88);
    padding: 1rem;
}

#version dd {
    margin: 0.15rem 0;
    margin-left: 1em;
}

#input {
    background-color: rgba(255, 255, 255, 0);
    padding: 1rem;
}

#screenshot {
    background-color: rgba(255, 255, 255, 0);
    padding: 1rem;
}

img {
    max-width: 100%;
}

table {
    border-collapse: collapse;
}

p.error_message {
    color: red;
}

label {
    display: block;
}

img.view_sample {
    display: block;
}

form input {
    display: flex;
}
