#producer_informations {
    display: block;
    align-content: center;
}

article.producer_info {
    display: inline-block;
    min-height: 100px;
    background-color: rgba(255, 255, 255, 0.88);
    padding: 1rem;
    width: 90%;
    margin-bottom: 1em;
}

article.producer_info img {
    max-width: 100px;
    max-height: 100px;
}

article.producer_info div {
    display: block;
}
