nav.pc {
    background-color: white;
}

nav.pc ul {
    display: table;
    margin: 0 auto;
    padding: 0;
    width: 80%;
    text-align: center;
}

nav.pc li {
    display: table-cell;
    width: 50px;
}

nav.pc a {
    display: block;
    width: 100%;
    text-decoration: none;
    color: #555;
    padding-bottom: 5px;
}

nav.pc li.current {
    border-bottom: 3px solid #92d050;
}
nav.pc li:hover {
    color: #e7da66;
    border-bottom: 3px solid mediumblue;
}
